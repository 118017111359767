import React from "react"
import PropTypes from "prop-types"

import Header from "./header.component"
import Footer from "./footer.component"

import "./layout.styles.scss"

const Layout = ({ className, children, showHeader, pathname, footerBorderColor }) => {
  return (
    <div className="wrapper">
      {showHeader && <Header pathname={pathname} />}
      <main className={`main ${className}`}>
        {children}
      </main>
      <Footer borderColor={footerBorderColor} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showHeader: PropTypes.bool,
  footerBorderColor: PropTypes.string
}

Layout.defaultProps = {
  showHeader: true,
  footerBorderColor: 'primary'
}

export default Layout
