import React, { useState } from "react"
import { Link } from "gatsby"
import { FaExternalLinkAlt } from "react-icons/fa"

import imgLogoInitials from "../images/logo-initials.png"
import imgLogoName from "../images/logo-name.png"

import "./header.styles.scss"

const Header = ({ pathname }) => {
  const [burgerNavActive, setBurgerNavActive] = useState(false)

  const handleSetBurgerNav = () => setBurgerNavActive(!burgerNavActive)

  const handleKeyDown = ev => {
    if (ev.keyCode === 13) {
      handleSetBurgerNav()
    }
  }

  return (
    <header className="header mb-4">
      <div className="container">
        <nav
          className="navbar is-transparent"
          role="navigation"
          aria-label="dropdown navigation"
        >
          <div className="navbar-brand">
            <Link to="/" className="navbar-item">
              <div className="logo-initials">
                <img src={imgLogoInitials} alt="" width="96" height="96" />
              </div>
              <img className="logo-name" src={imgLogoName} alt="" width="200" height="21" />
            </Link>
            <span
              role="button"
              tabIndex={0}
              className={`navbar-burger is-size-1 ${
                burgerNavActive ? "is-active" : ""
              }`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={handleSetBurgerNav}
              onKeyDown={handleKeyDown}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </span>
          </div>
          <div className={`navbar-menu ${burgerNavActive ? "is-active" : ""}`}>
            <div className="navbar-end is-align-items-center">
              <Link to="/" className="navbar-item is-hidden-desktop">
                Home
              </Link>
              <Link to="/about" className={`navbar-item ${pathname === "/about" ? "is-active" : ""}`}>
                About
              </Link>
              <Link to="/guides" className={`navbar-item ${pathname === "/guides" ? "is-active" : ""}`}>
                Guides
              </Link>
              <Link to="/contact" className={`navbar-item ${pathname === "/contact" ? "is-active" : ""}`}>
                Contact
              </Link>
              <a
                className="navbar-item"
                href="https://paraskyflutestudios.com"
                target="_blank"
                rel="noreferrer"
              >
                Parasky Flute Studios <FaExternalLinkAlt size=".8rem" />
              </a>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

Header.defaultProps = {
  size: "sm",
}

export default Header
