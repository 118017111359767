import React from "react"
import { Link } from "gatsby"
import {
  FaExternalLinkAlt,
  FaYoutube,
  FaFacebookF,
  // FaInstagram,
} from "react-icons/fa"

import imgLogo from "../images/logo-vertical-white.png"

import "./footer.styles.scss"

const Footer = ({ borderColor }) => (
  <>
    <footer
      className={`footer has-background-dark has-text-white ${
        borderColor === "info"
          ? "footer--info"
          : borderColor === "danger"
          ? "footer--danger"
          : borderColor === "purple"
          ? "footer--purple"
          : "footer-primary"
      }`}
    >
      <div className="container">
        <div className="footer__links is-flex is-justify-content-center has-text-centered is-flex-wrap-wrap mt-4">
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/topics">Topics</Link>
          <Link to="/contact">Contact</Link>
          <a href="https://paraskyflutestudios.com">
            paraskyflutestudios.com <FaExternalLinkAlt size=".8rem" />
          </a>
        </div>
        <div className="footer__aside">
          <div className="footer__social is-flex is-justify-content-center mt-5">
            <a
              className="button is-outlined py-5 px-3 mx-5"
              href="https://www.youtube.com/Paraskyflutestudios"
              target="_blank"
              rel="noreferrer"
            >
              <FaYoutube size="1.8rem" />
            </a>
            <a
              className="button is-outlined py-5 px-3 mx-5"
              href="https://www.facebook.com/dan.parasky"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookF size="1.8rem" />
            </a>
            {/* <a
              className="button is-outlined py-5 px-3 mx-5"
              href="#"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram size="1.8rem" />
            </a> */}
          </div>
          <div className="footer__logo is-flex is-justify-content-center mt-6">
            <figure className="image">
              <img src={imgLogo} alt="logo" />
            </figure>
          </div>
          <div className="footer__copyright is-flex is-align-items-center is-justify-content-center mt-6">
            <span className="copyright__container has-text-grey-lighter is-size-6">
              © {(new Date().getFullYear())} Dan Parasky
              <span className="copyright__div">|</span>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </span>
          </div>
          <div className="is-flex is-justify-content-center mt-4">
            <div className="content is-size-7 has-text-grey-lighter">
              Site development by:{" "}
              <a
                href="https://justinspegele.com"
                target="_blank"
                rel="noreferrer"
                className=" has-text-grey-light"
              >
                justinspegele.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
)

Footer.defaultProps = {
  borderColor: "primary",
}

export default Footer
